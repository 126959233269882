








































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import Arrow from '@/assets/icons/Arrow.vue';

const DEFAULT_FILL = 'var(--neutralcolor-low-pure)';

@Component({
  components: {
    Arrow,
  },
})
export default class HeaderListDoubts extends Vue {
  @Prop() setChangeSelect!: Function;
  @Prop({ default: false }) hasDoubts!: boolean;

  private optionMenu = 0;

  private DEFAULT_FILL = DEFAULT_FILL;

  setOption(option: number) {
    if (this.optionMenu !== option) {
      this.optionMenu = option;
      this.$emit('tabActive', option);
    }
  }
}
