


































































import {
  Component, Prop, Watch, Mixins,
} from 'vue-property-decorator';

import ErrorLoading from '@/components/ErrorLoading/ErrorLoading.vue';

import mixinDoubts from '@/mixins/doubts';

import Arrow from '@/assets/icons/Arrow.vue';

import { Doubt as IDoubt } from '@/globalInterfaces/Doubts';

import Doubt from './Doubts.vue';
import HeaderListDoubts from './HeaderListDoubts.vue';
import ListDoubtsLoading from './components/ListDoubtsLoading.vue';

const NO_DOUBT = 'Não há dúvidas para esse tópico.';
const NO_DOUBT_SENT = 'Você ainda não enviou nenhuma dúvida.';
const NUMBER_PAGE_INITIAL = 0;
const NUMBER_TAB_ACTIVE_ALL_DOUBTS = 0;

@Component({
  components: {
    Arrow,
    Doubt,
    HeaderListDoubts,
    ListDoubtsLoading,
    ErrorLoading,
  },
})
export default class ListDoubts extends Mixins(mixinDoubts) {
  @Prop({ default: () => [] }) allDoubts!: Array<IDoubt>;
  @Prop({ default: () => [] }) myDoubts!: Array<IDoubt>;
  @Prop({ default: false }) showNameSubject!: boolean;
  @Prop() isLoadingDoubts!: boolean;
  @Prop() errorAllDoubts!: boolean;
  @Prop() errorMyDoubts!: boolean;
  @Prop({ default: 370 }) heightList!: number;
  @Prop({ default: 'flex-start' }) buttonPosition!: string;
  @Prop({ required: false }) selectedIdBook!: string;
  @Prop({ required: false }) selectedIdTopic!: string;
  @Prop({ required: true }) quantityDoubtsForRequest!: number;
  @Prop({ required: true }) totalAllDoubts!: number;
  @Prop({ required: true }) totalMyDoubts!: number;

  private order = true;
  private tabActive = 0;
  private currentPageAllDoubts = NUMBER_PAGE_INITIAL;
  private currentPageMyDoubts = NUMBER_PAGE_INITIAL;
  private allDoubtsLength = 0;
  private myDoubtsLength = 0;

  private showDoubts: Array<Record<string, any>> = [];

  async mounted() {
    this.loadData();
  }

  updated() {
    this.setLength();
  }

  get showFeedbackError() {
    return (this.isShowAllDoubts && this.errorAllDoubts)
            || (!this.isShowAllDoubts && this.errorMyDoubts);
  }

  get getIdStudent() {
    return this.$store.getters.profile.idstudent;
  }

  get doubtsTabActive() {
    return this.isShowAllDoubts ? this.allDoubts : this.myDoubts;
  }

  get isShowAllDoubts() {
    return this.tabActive === 0;
  }

  get showText() {
    if (this.isShowAllDoubts) return NO_DOUBT;
    return this.selectedIdBook && !this.myDoubts.length ? NO_DOUBT : NO_DOUBT_SENT;
  }

  get isDisableButtonShowMore() {
    return !this.doubtsTabActive.length || this.doubtsTabActive.length < this.quantityDoubtsForRequest;
  }

  get isLimitTotalDoubts() {
    return this.tabActive === NUMBER_TAB_ACTIVE_ALL_DOUBTS
      ? this.allDoubts.length >= this.totalAllDoubts
      : this.myDoubts.length >= this.totalMyDoubts;
  }

  @Watch('selectedIdBook')
  @Watch('selectedIdTopic')
  resetCurrentPage() {
    this.currentPageAllDoubts = NUMBER_PAGE_INITIAL;
    this.currentPageMyDoubts = NUMBER_PAGE_INITIAL;
  }

  @Watch('allDoubts')
  @Watch('myDoubts')
  @Watch('tabActive')
  loadData() {
    this.sortListDoubts();
  }

  @Watch('allDoubtsLength')
  @Watch('myDoubtsLength')
  setScrollList() {
    const { list }: any = this.$refs;

    if (!list?.scrollHeight) return;

    if (this.order) {
      list.scrollTop = list.scrollHeight;
      return;
    }

    list.scrollTop = 0;
  }

  setLength() {
    if (this.isShowAllDoubts && this.allDoubts.length !== this.allDoubtsLength) {
      this.allDoubtsLength = this.allDoubts.length;
    } else if (!this.isShowAllDoubts && this.myDoubts.length !== this.myDoubtsLength) {
      this.myDoubtsLength = this.myDoubts.length;
    }
  }

  setScrollListTopZero() {
    const { list }: any = this.$refs;
    if (list?.scrollHeight) list.scrollTop = 0;
  }

  setChangeSelect(e: any) {
    const { value } = e.target;

    if (value === '0') this.sortRecent();
    else this.sortStart();
  }

  sortRecent() {
    this.order = true;

    if (this.isShowAllDoubts) this.showDoubts = this.allDoubts.slice();
    else this.showDoubts = this.myDoubts;

    this.setScrollListTopZero();
  }

  sortStart() {
    this.order = false;
    this.showDoubts = this.doubtsTabActive.slice().reverse();

    this.setScrollListTopZero();
  }

  setTabActive(option: number) {
    this.tabActive = option;
  }

  sortListDoubts() {
    if (this.isShowAllDoubts) {
      this.showDoubts = this.order ? this.allDoubts.slice() : this.allDoubts.slice().reverse();
      return;
    }

    this.showDoubts = this.order ? this.myDoubts : this.myDoubts.slice().reverse();
  }

  moreDoubts() {
    if (this.isShowAllDoubts) {
      this.currentPageAllDoubts += 1;
      this.$emit('more-doubts', this.currentPageAllDoubts, this.tabActive);
    } else {
      this.currentPageMyDoubts += 1;
      this.$emit('more-doubts', this.currentPageMyDoubts, this.tabActive);
    }
  }
}
